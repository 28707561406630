/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-earmark-word-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M9.293 0H4a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4.707A1 1 0 0013.707 4L10 .293A1 1 0 009.293 0M9.5 3.5v-2l3 3h-2a1 1 0 01-1-1M5.485 6.879l1.036 4.144.997-3.655a.5.5 0 01.964 0l.997 3.655 1.036-4.144a.5.5 0 01.97.242l-1.5 6a.5.5 0 01-.967.01L8 9.402l-1.018 3.73a.5.5 0 01-.967-.01l-1.5-6a.5.5 0 11.97-.242z"/>',
    },
});
